<template>
    <div class="market-grid-home">
        <!-- <div v-if="loading">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div> -->
        <div class="row tgju-widgets-row dashboard-widgets-technical-list">
            <div class="profile-mobile-view market-select-mobile-view" style="width: 100%;">
                <div class="select-with-filter" style="display: block;">
                    <!-- <div v-if="!current_branch_list" class="v-select-loading">
                        <div class="text-center w100-w">
                            <div class="widget-loading"></div>
                        </div>
                    </div> -->
                    <slot v-if="retail_params != 'car'">
                        <slot v-if="return_category_id != '5001'">
                            <v-select label="دسته بندی" v-if="current_branch_list" @input="setSelected" return-object
                            class="profile-mobile-view-select" :items="current_branch_list" item-text="branch" :item-value="item => ({branch: item.branch, sub_category_id: item.sub_category_id, branch_id: item.branch_id})"
                            outlined style="margin-top: 5px !important;" ></v-select>
                        </slot>
                        <slot v-if="retail_params != 'iron'">
                            <v-select label="برند" v-if="current_brand_list"  @input="setSelectedBrand" 
                            class="profile-mobile-view-select" :items="current_brand_list" item-text="brand" :item-value="item => ({branch: item.branch, sub_category_id: item.sub_category_id, branch_id: item.branch_id, brand: item.brand, brand_id: item.brand_id})"
                            outlined></v-select>

                        </slot>
                    </slot>
                </div>
            </div>
        </div>
        <!-- <div class="market-main-item breakline-active">
            <div class="breakline-row"> {{ show_branch }} </div>
        </div> -->

        <div class="market-main-item">
            <div v-for="product in products" :key="product.id" v-if="!show_branch && !show_brand">
                <div class="market-main-item-row r-low" v-bind:id="'drop' + product.id"
                    v-on:click="show_metadata(product.id)" v-if="!show_branch" style="border-bottom: 1px solid #eaeaea;">

                    <!-- <div class="market-main-item-col-logo" v-if="product.image">
                        <img :src="getImage(product.image)" alt="">
                    </div> -->
                    <div class="market-main-item-col item-col-right">
                        <div class="market-main-item-title">
                            {{ product.title }}
                        </div>
                        <div class="market-main-item-time">
                            {{ convert_date(product.updated_at) }}
                        </div>
                    </div>
                    <div class="market-main-item-col item-col-left">
                        <div class="market-main-item-price">
                            <div>{{ separate_price(product.price) }}</div>
                            <div class="item-price-badge">تومان</div>
                        </div>
                    </div>
                </div>
                <!-- && !isEmpty(get_product_items(product)) -->
                <div class="row tgju-widgets-row" v-bind:id="'dropdown' + product.id" style="display: none;"
                    v-if="!show_branch">
                    <div class="li-head">
                        <div class="profile-inner-table-row">
                            <div class="profile-inner-table-name">خصیصه</div>
                            <div class="profile-inner-table-value">مقدار</div>
                        </div>
                    </div>
                    <!-- <div class="product-image" v-if="product.image">
                        <img :src="getImage(product.image)" alt="">
                    </div> -->
                    <div class="profile-inner-table-row" v-for="(item, key) in get_product_items(product)" :key="item"
                        style="background-color: rgb(244, 244, 244);">
                        <div class="profile-inner-table-name">{{ key }}</div>
                        <div class="profile-inner-table-value">{{ item }}</div>
                    </div>
                </div>

            </div>

            <infinite-loading @infinite="getAllProduct" v-if="!show_branch && !show_brand">
                <span slot="no-more">محتوا به طور کامل بارگیری شد .</span>
            </infinite-loading>

            <slot v-if="show_branch">
                <div  v-for="bproduct in branchProducts" :key="bproduct.id">
                    <div class="market-main-item-row r-low" v-bind:id="'drop' + bproduct.id"
                        style="border-bottom: 1px solid #eaeaea;" v-on:click="show_metadata(bproduct.id)">
                        <!-- <div class="market-main-item-col-logo" v-if="bproduct.image">
                            <img :src="getImage(bproduct.image)" alt="">
                        </div> -->
                        <div class="market-main-item-col item-col-right">
                            <div class="market-main-item-title">
                                {{ bproduct.title }}
                            </div>
                            <div class="market-main-item-time">
                                {{ convert_date(bproduct.updated_at) }}
                            </div>
                        </div>
                        <div class="market-main-item-col item-col-left">
                            <div class="market-main-item-price">
                                <div>{{ separate_price(bproduct.price) }}</div>
                                <div class="item-price-badge">تومان</div>
                            </div>
                        </div>
                    </div>
                    <!-- && !isEmpty(get_product_items(bproduct)) -->
                    <div class="row tgju-widgets-row" v-bind:id="'dropdown' + bproduct.id" style="display: none;"
                        v-if="show_branch">
                        <div class="li-head">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">خصیصه</div>
                                <div class="profile-inner-table-value">مقدار</div>
                            </div>
                        </div>
                        <!-- <div class="product-image" v-if="bproduct.image">
                            <img :src="getImage(bproduct.image)" alt="">
                        </div> -->
                        <div class="profile-inner-table-row" v-for="(item, key) in get_product_items(bproduct)" :key="item"
                            style="background-color: rgb(244, 244, 244);">
                            <div class="profile-inner-table-name">{{ key }}</div>
                            <div class="profile-inner-table-value">{{ item }}</div>
                        </div>
                    </div>

                </div>
                <infinite-loading :identifier="infiniteId" @infinite="getBranchValue" v-if="show_branch">
                    <span slot="no-more">محتوا به طور کامل بارگیری شد .</span>
                </infinite-loading>
            </slot>

            <slot v-if="show_brand">
                <div  v-for="bproduct in brandProducts" :key="bproduct.id">
                    <div class="market-main-item-row r-low" v-bind:id="'drop' + bproduct.id"
                        style="border-bottom: 1px solid #eaeaea;" v-on:click="show_metadata(bproduct.id)">
                        <!-- <div class="market-main-item-col-logo" v-if="bproduct.image">
                            <img :src="getImage(bproduct.image)" alt="">
                        </div> -->
                        <div class="market-main-item-col item-col-right">
                            <div class="market-main-item-title">
                                {{ bproduct.title }}
                            </div>
                            <div class="market-main-item-time">
                                {{ convert_date(bproduct.updated_at) }}
                            </div>
                        </div>
                        <div class="market-main-item-col item-col-left">
                            <div class="market-main-item-price">
                                <div>{{ separate_price(bproduct.price) }}</div>
                                <div class="item-price-badge">تومان</div>
                            </div>
                        </div>
                    </div>
                    <!-- && !isEmpty(get_product_items(bproduct)) -->
                    <div class="row tgju-widgets-row" v-bind:id="'dropdown' + bproduct.id" style="display: none;"
                        v-if="show_brand">
                        <div class="li-head">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">خصیصه</div>
                                <div class="profile-inner-table-value">مقدار</div>
                            </div>
                        </div>
                        <!-- <div class="product-image" v-if="bproduct.image">
                            <img :src="getImage(bproduct.image)" alt="">
                        </div> -->
                        <div class="profile-inner-table-row" v-for="(item, key) in get_product_items(bproduct)" :key="item"
                            style="background-color: rgb(244, 244, 244);">
                            <div class="profile-inner-table-name">{{ key }}</div>
                            <div class="profile-inner-table-value">{{ item }}</div>
                        </div>
                    </div>

                </div>
                <infinite-loading :identifier="infiniteId" @infinite="getBrandValue" v-if="show_brand">
                    <span slot="no-more">محتوا به طور کامل بارگیری شد .</span>
                </infinite-loading>
            </slot>

        </div>
    </div>
</template>
    
<script>
import InfiniteLoading from "vue-infinite-loading";
import marketStaticValues from "@/assets/static_market_page_value";
import static_market from "@/assets/static_market";
import vSelect2 from 'vue-select';
import moment from "moment-jalaali";
Vue.component('v-select2', vSelect2);


export default {
    name: 'subCategoryList',
    components: {
        InfiniteLoading,
    },
    data: function () {
        return {
            loading: false,
            branch_list: [],
            brand_list:{},
            selected: '',
            products: [],
            branchProducts: [],
            brandProducts:[],
            show_branch: false,
            show_brand: false,
            page: 1,
            opage: 1,
            scrollTop: 0,
            branch: "",
            branch_id:"",
            brand:"",
            brand_id:"",
            infiniteId: "",
            show: false,

        }
    },
    destroyed() {

    },
    beforeMount() {

    },
    created() {
    },
    mounted() {
        this.getBranchList();
        this.getBrandList();
    },
    beforeDestroy() {
    },
    methods: {

        getImage($url) {
            var name = $url.substring($url.lastIndexOf("/") + 1, $url.length);
            return "https://marketban.com/uploads/" + name;
            // if (this.$route.params.retail_id == 'food') {
            //     name = 'بازار مواد غذایی';
            // } else if (this.$route.params.retail_id == 'computer') {
            //     name = 'بازار کامپیوتر';
            // } else if (this.$route.params.retail_id == 'tools') {
            //     name = 'بازار ابزارآلات';
            // } else if (this.$route.params.retail_id == 'digital') {
            //     name = 'بازار لوازم دیجیتال';
            // } else if (this.$route.params.retail_id == 'home_appliances') {
            //     name = 'بازار لوازم خانگی';
            // } else if (this.$route.params.retail_id == 'car') {
            //     name = 'بازار خودرو';
            // } else {
            //     return $url;
            // }

            // return 'https://cdn.marketban.com/' + name + '/' + ($url.substring($url.lastIndexOf("/") + 1, $url.length)).replace(".com-s", "").replace(".com-large-s", "").replace(".com-l-s", "").replace(".com-b-s", "").replace(".cpm-l-s", "");
        },

        setSelected(value) {
            console.log('setSelected');

            this.branch = value.branch
            this.branch_id = value.branch_id
            this.opage = 1;
            this.infiniteId += 1;
            this.show_branch = true;
            this.show_brand=false
            this.branchProducts = []
            this.brandProducts = []
            this.brand='نمای کلی'
            this.brand_id="all"
            this.brand_list={}
            this.getBrandList();

        },
        setSelectedBrand(value){
            console.log('setSelectedBrand');
            this.brand=value.brand
            this.brand_id=value.brand_id
            this.opage = 1;
            this.infiniteId += 1;
            this.show_branch = false;
            this.show_brand=true
            this.branchProducts = []
            this.brandProducts = []
            //this.getBrandValue();
        },
        getBranchValue($state) {
            console.log('getBranchValue');
            this.show_branch = true
            var branch = this.branch
            var retail_id = this.$route.params.retail_id;
            var subCategoryID = this.$route.params.subcategory_id;
            this.loading = true;
            let vm = this;
            this.$helpers
                .makeRequest("GET", "/retail/branchvalue?branch=" + branch + '&market=' + retail_id + '&subcategory_id=' + subCategoryID + '&page=' + this.opage)
                .then((response) => {
                    if (response.status == 200) {
                        this.loading = false;
                        if (response.data.last_page >= this.opage) {
                            this.opage = this.opage + 1;
                            $.each(response.data.data, function (key, value) {
                                vm.branchProducts.push(value);
                            });
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    }
                });
        },
        getBrandValue($state) {
            console.log('getBrandValue');
            this.show_brand = true
            this.show_branch = false
            var branch = this.branch
            var retail_id = this.$route.params.retail_id;
            var subCategoryID = this.$route.params.subcategory_id;
            this.loading = true;
            let vm = this;
            this.$helpers
                .makeRequest("GET", "/retail/brandvalue?branch=" + branch + '&market=' + retail_id + '&subcategory_id=' + subCategoryID + '&brand=' + this.brand + '&page=' + this.opage)
                .then((response) => {
                    console.log(response);
                    
                    if (response.status == 200) {
                        this.loading = false;
                        if (response.data.last_page >= this.opage) {
                            this.opage = this.opage + 1;
                            $.each(response.data.data, function (key, value) {
                                vm.brandProducts.push(value);
                            });
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    }
                });
        },
        getBranchList() {
            var selected_id = this.$route.params.subcategory_id;
            var retail_id = this.$route.params.retail_id;
            this.loading = true;
        
            this.$helpers
                .makeRequest("GET", "/retail/branch?selected_id=" + selected_id + '&market=' + retail_id)
                .then((response) => {
                    this.branch_list = response.data;
                    this.branch_list.unshift({ branch_id: "all", branch: 'نمای کلی' });
                    this.loading = false;
                    this.$store.commit('setMarketLvl3', this.branch_list);
                    this.$store.commit('setMarketlayer', 3);
                });
        },
        getBrandList() {
            var selected_id = this.$route.params.subcategory_id;
            var retail_id = this.$route.params.retail_id;
            var branch = this.branch
            this.loading = true;
            this.$helpers
                .makeRequest("GET", "/retail/brand?selected_id=" + selected_id + '&market=' + retail_id+'&branch_id=' + this.branch_id)
                .then((response) => {
                    console.log('getBrandList')
                    console.log(response.data);
                    this.brand_list = response.data;
                    this.brand_list.unshift({ brand_id: "all", brand: 'نمای کلی' });
                    this.loading = false;
                    //this.$store.commit('setMarketLvl3', this.branch_list);
                    this.$store.commit('setMarketlayer', 3);
                });
        },
        getAllProduct($state) {
            this.show_branch = false
            this.show_brand = false
            let vm = this;
            this.loading = true;
            var branch = this.branch ? this.branch : 'نمای کلی';
            var retail_id = this.$route.params.retail_id;
            var subCategoryID = this.$route.params.subcategory_id;
            this.$helpers
                .makeRequest("GET", "/retail/branchvalue?branch=" + branch + '&market=' + retail_id + '&subcategory_id=' + subCategoryID + '&page=' + this.page)
                .then((response) => {
                    if (response.status == 200) {
                        this.loading = false;
                        if (response.data.last_page >= this.page) {
                            this.page = this.page + 1;
                            $.each(response.data.data, function (key, value) {
                                vm.products.push(value);
                            });
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    }
                });
        },
        separate_price(num) {
            var str = num.toString();
            //str = str.replace(/\,/g, '');
            var objRegex = new RegExp('(-?[0-9]+)([0-9]{3})');

            while (objRegex.test(str)) {
                str = str.replace(objRegex, '$1,$2');
            }
            return str;
        },
        convert_date(date) {
            var time = moment(date).format('HH:mm:ss jYYYY/jM/jD');
            return time
        },
        show_metadata(product_id) {
            var el = document.getElementById('dropdown' + product_id);
            var main_el = document.getElementById('drop' + product_id);
            if (main_el.classList.contains('drop_active')) {
                main_el.classList.remove("drop_active")
            } else {
                main_el.classList.add("drop_active");
            }
            if (el) {
                var status_dropdown = el.style.display;
                if (status_dropdown == 'none') {
                    el.style.display = 'block'
                } else {
                    el.style.display = 'none'
                }
            }
        },
        get_product_items(product) {
            let items = product.items;
            if (items != null) {
                let convertjsonToArray = JSON.parse(items);
                // ساخت دوباره آبجکت برای این است که یک سری فیلتر اعمنال نماییم به طور مثال خالی ها بر گردانده نشوند
                const obj = new Object();
                for (const [key, value] of Object.entries(convertjsonToArray)) {
                    if (key != null && value != null) {
                        obj[key] = value;
                    }
                }
                return obj;
            }
            return [];
        },
        isEmpty(obj) {
            for (const prop in obj) {
                if (Object.hasOwn(obj, prop)) {
                    return false;
                }
            }
            return true;
        }
    },
    computed: {
        current_branch_list() {
            return this.$store.state.marketLvl3;
        },
        current_brand_list(){
            return this.brand_list;
        },
        retail_params(){
            return this.$route.params.retail_id;
        },
        return_category_id(){
            return this.$route.params.subcategory_id;
        }
    },
    watch: {
        $route(to, from) {
            this.retail_id = to.params.retail_id;
        }
    }
}
</script>

<style>
.li-head {
    background: #e2eaf0;
    border-bottom: 1px solid #d4dce1;
    border-top: 1px solid #d4dce1;
    font-weight: 600 !important;
    color: #222;
    font-size: 12px;
    display: flex;
    padding-left: 10px;
}

.profile-inner-table-row {
    display: flex;
    align-items: center;
    padding: 6px 20px 9px;
    flex-grow: 1;
}

.profile-inner-table-name {
    color: #222;
    font-weight: 600;
    flex-grow: 1;
}

.market-grid-home {
    padding: 0;
}
</style>
    